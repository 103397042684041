

import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KisiKurumForm from "@/components/forms/KisiKurumForm.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {AdresEntity} from "@/entity/AdresEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import MtsYetkiliKurumSecimi from "@/components/eicrapro/uyap/MtsYetkiliKurumSecimi.vue";
import {KurumEntity} from "@/entity/KurumEntity";
import {SirketTipi} from "@/enum/SirketTipi";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";

@Component({
    components: {
        MtsYetkiliKurumSecimi,
        FormWrapper,
        KisiKurumForm
    }
})
export default class MuvekkilForm extends Mixins(ObjectInputMixin) {

    @Prop({required: true}) vekaletId!: number;
    @Ref('formWrapper') formWrapper!: FormWrapper;
    searched: boolean = false;
    isMTS = false;
    mtsKurumBilgileri:any = null;
    isAddressEmpty: boolean = false
    isKisi: boolean|null = null;

    save() {
        if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis) {
            if (this.localValue.kisi.adresler.length === 0) {
                this.isAddressEmpty = true;
                return;
            }
        }
        if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket) {
            if (this.localValue.kurum.adresler.length === 0) {
                this.isAddressEmpty = true;
                return;
            }
        }
        if (this.isMTS)
            this.saveMTS();
        else {
            this.formWrapper.onFly = true;
            this.isAddressEmpty = false;
            this.$store.state.createTcKimlikErrors = [];
            let data: any = {};
            if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis) {
                if (this.localValue.kisi.id === 0) {
                    this.localValue.kisi.id = null;
                }
                if (this.localValue.kisi.id == null) {
                    StatusCodeHelper.dontShow422Alert = true;
                    this.$http.post('/api/v1/kisi/', this.localValue.kisi)
                        .then(response => {
                            data.kisiler = [response.data.id];
                            this.localValue.kisi.id = response.data.id;
                            this.saveBankaHesaplari(this.localValue);
                            this.saveAdresler(this.localValue);
                            this.insertMuvekkil(data);
                            StatusCodeHelper.dontShow422Alert = false;
                        }).catch((error) => {
                        if (error.response.status == 422) {
                            this.$store.state.createTcKimlikErrors = error.response.data.errors;
                        }
                        StatusCodeHelper.dontShow422Alert = false;
                    }).finally(() => (this.formWrapper.onFly = false));
                } else {
                    this.$http.put('/api/v1/kisi/' + this.localValue.kisi.id, this.localValue.kisi)
                        .then(response => {
                            data.kisiler = [response.data.id];
                            this.saveBankaHesaplari(this.localValue);
                            this.saveAdresler(this.localValue);
                            this.insertMuvekkil(data);
                        }).finally(() => (this.formWrapper.onFly = false));
                }
            } else if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket) {
                if (this.localValue.kurum.id === 0) {
                    this.localValue.kurum.id = null;
                }

                if (this.localValue.kurum.id == null) {
                    this.$http.post('/api/v1/kurum/', this.localValue.kurum)
                        .then(response => {
                            data.kurumlar = [response.data.id];
                            this.localValue.kurum.id = response.data.id;
                            this.saveBankaHesaplari(this.localValue);
                            this.saveAdresler(this.localValue);
                            this.insertMuvekkil(data);
                        }).finally(() => (this.formWrapper.onFly = false));
                } else {
                    data.kurumlar = [this.localValue.kurum.id];
                    this.saveBankaHesaplari(this.localValue);
                    this.saveAdresler(this.localValue);
                    this.insertMuvekkil(data);
                }
            }
        }
    }

    async saveMTS() {
        this.formWrapper.onFly = true;
        let borcluData = (await this.$http?.get('/api/v1/kurum/filter?uyapKurum=' + this.mtsKurumBilgileri.kisiKurumId)).data;
        if (borcluData.length > 0) {
            await this.$http.post('/api/v1/vekalet/' + this.vekaletId + '/muvekkil', {kurumlar: [borcluData[0].id]});
        } else {
            let yeniKurum:any = new KurumEntity();
            let cevap = await this.$uyap.KurumSorgulama().run({vergiNo: this.mtsKurumBilgileri.vergiNo,kurumAdi:'',mersisNo:'',detsisNo:''});
            yeniKurum.sirket_adi = cevap[0].kurumAdi;
            yeniKurum.vergi_no = this.mtsKurumBilgileri.vergiNo;
            yeniKurum.ticaret_sicil_no = cevap[0].ticaretSicilNo;
            yeniKurum.mersis_no = cevap[0].mersisNo
            yeniKurum.sirket_tipi = cevap[0].kamuOzel = 'O' ? SirketTipi.ozel : SirketTipi.kamu;
            yeniKurum.uyap_kisi_kurum_id = this.mtsKurumBilgileri.kisiKurumId;
            yeniKurum.is_mts_kurumu = true;
            let yeniKurumData = (await this.$http?.post('/api/v1/kurum/', yeniKurum)).data;
            await this.$http.post('/api/v1/vekalet/' + this.vekaletId + '/muvekkil', {kurumlar: [yeniKurumData.id]});
        }
        this.$emit("onSuccess");
        this.resetForm();
        this.formWrapper.onFly = false;
    }

    onKisiKurumChange() {
        this.searched = true;
    }

    @Watch("localValue.kisi_kurum_turu")
    checkChange() {
        if(this.localValue.kisi_kurum_turu === KisiKurumTuru.sahis) {
            this.isKisi = false;
        }else{
            this.isKisi = true;
        }
    }

    resetForm() {
        delete this.localValue.kisi;
        delete this.localValue.kurum;
        this.localValue.kisi_kurum_turu = KisiKurumTuru.sahis;
        this.searched = false;
        this.mtsKurumBilgileri = null;
        this.isKisi = null
        this.$emit("onClose");
    }

    private insertMuvekkil(data: any) {
        this.$http.post('/api/v1/vekalet/' + this.vekaletId + '/muvekkil', data)
            .then(() => {
                this.$emit("onSuccess");
                this.resetForm();
            }).finally(() => (this.formWrapper.onFly = false));
    }

    private saveAdresler(muvekkil: MuvekkilEntity) {
        let adresler = muvekkil.kisi ? muvekkil.kisi.adresler : (muvekkil.kurum ? muvekkil.kurum.adresler : []);
        adresler.forEach((adres: AdresEntity) => {
            if (adres.id) {
                this.$http.put('/api/v1/adres/' + adres.id, adres).finally(() => (this.formWrapper.onFly = false));
            } else {
                let url = muvekkil.kisi ? ('kisi/' + muvekkil.kisi.id) : (muvekkil.kurum ? ('kurum/' + muvekkil.kurum.id) : null);
                if (url) {
                    this.$http.post('/api/v1/' + url + '/adres', adres).finally(() => (this.formWrapper.onFly = false));
                }
            }
        });
    }

    private saveBankaHesaplari(muvekkil: MuvekkilEntity) {
        let hesaplar = this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis ? muvekkil.kisi.banka_hesaplari : (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket ? muvekkil.kurum.banka_hesaplari : []);
        hesaplar.forEach((hesap: BankaHesabiEntity) => {
            if (hesap.id) {
                this.$http.put('/api/v1/banka-hesabi/' + hesap.id, hesap).finally(() => (this.formWrapper.onFly = false));
            } else {
                let url = muvekkil.kisi ? ('kisi/' + muvekkil.kisi.id) : (muvekkil.kurum ? ('kurum/' + muvekkil.kurum.id) : null);
                if (url) {
                    this.$http.post('/api/v1/' + url + '/banka-hesabi', hesap).finally(() => (this.formWrapper.onFly = false));
                }
            }
        });
    }

}
